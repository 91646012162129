<template>
	<v-container fluid>
		<div class="d-flex flex-wrap mb-4" style="gap: 16px">
			<tarjeta-top
				width="100%"
				max-width="300"
				outlined
				v-for="topCard in situacionesContratos"
				:key="topCard.text"
				:loading="topCard.value === null"
				:texto="topCard.texto"
				:value="topCard.value"
				:unit="topCard.unit"
				:icon="topCard.icon"
				:increment="topCard.increment"
				:increment-way="topCard.incrementWay"
				v-if="$root.acceso(topCard.acceso)"
			></tarjeta-top>
		</div>

		<v-row>
			<v-col v-if="$root.acceso('VER_CONTRATOS')" cols="12" class="col-md-6">
				<div v-if="!loaded.meses" class="d-flex align-center justify-center">
					<v-progress-circular indeterminate color="primary"></v-progress-circular>
				</div>
				<contratos-por-mes @loaded="loaded.meses = true" />
			</v-col>
			<v-col v-if="$root.acceso('VER_CONTRATOS')" cols="12" class="col-md-6">
				<div v-if="!loaded.donut" class="d-flex align-center justify-center">
					<v-progress-circular indeterminate color="primary"></v-progress-circular>
				</div>
				<doughnut-chart @loaded="loaded.donut = true" />
			</v-col>
			<v-col v-if="$root.acceso('VER_INCIDENCIAS')" cols="12" class="col-md-6">
				<tabla-incidencias />
			</v-col>
			<v-col v-if="$root.acceso('VER_CONTRATOS')" cols="12" class="col-md-6">
				<tabla-ultimos-contratos />
			</v-col>
			<!-- <v-col cols="12" class="col-md-6">
				<tabla-comisionistas />
			</v-col>
			<v-col cols="12" class="col-md-6">
				<tabla-tareas />
			</v-col>
			<v-col cols="12" class="col-md-6">
				<tabla-notificaciones />
			</v-col>-->
		</v-row>
	</v-container>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
	components: {
		ContratosPorMes: () => import("@/modules/contratos/components/ContratosPorMes.vue"),
		DoughnutChart: () => import("@/components/DoughnutChart.vue"),
		TarjetaTop: () => import("@/components/TarjetaTop.vue"),
		TablaUltimosContratos: () =>
			import("@/modules/contratos/components/TablaUltimosContratos.vue"),
		TablaComisionistas: () => import("@/components/TablaComisionistas.vue"),
		TablaTareas: () => import("@/components/TablaTareas.vue"),
		TablaNotificaciones: () => import("@/components/TablaNotificaciones.vue"),
		TablaIncidencias: () => import("@/modules/incidencias/components/TableIncidencias.vue"),
	},
	data() {
		return {
			loaded: {
				donut: false,
				meses: false,
			},
			situacionesContratos: {
				// numeroImpagados: {
				// 	icon: "mdi-currency-eur-off",
				// 	texto: "Impagados",
				// 	value: null,
				// 	unit: "",
				// 	acceso: 'CONTROL_IMPAGADOS',
				// },
				// totalImpagados: {
				// 	icon: "mdi-currency-eur-off",
				// 	texto: "Monto Impagados",
				// 	value: null,
				// 	unit: "€",
				// 	acceso: 'CONTROL_IMPAGADOS',
				// },
				contratosActivos: {
					icon: "mdi-check-all",
					texto: "Contratos Activos",
					value: null,
					unit: "",
					acceso: 'VER_CONTRATOS',
					incrementWay: 'up'
				},
				contratosBaja: {
					icon: "mdi-alert-circle-outline",
					texto: "Contratos Baja",
					value: null,
					unit: "",
					acceso: 'VER_CONTRATOS',
					incrementWay: 'down'
				},
				contratosCortado: {
					icon: "mdi-skull",
					texto: "Contratos Cortados",
					value: null,
					unit: "",
					acceso: 'VER_CONTRATOS',
					incrementWay: 'down'
				},
				totalClientesAlta: {
					icon: "mdi-account-group",
					texto: "Clientes Alta",
					value: null,
					unit: "",
					acceso: 'VER_CONTRATOS',
					incrementWay: 'up'
				},
				// totalClientesBajaDeuda: {
				// 	icon: "mdi-cash-remove",
				// 	texto: "Clientes Baja con Deuda",
				// 	value: null,
				// 	unit: "",
				// 	acceso: 'ESTADISTICAS_IMPAGADOS',
				// },
				totalContratos: {
					icon: "mdi-file-document",
					texto: "Altas de Contratos",
					value: null,
					unit: "",
					acceso: 'VER_CONTRATOS',
				},
				// comisiones: {
				// 	icon: "mdi-currency-eur",
				// 	texto: "Comisiones",
				// 	value: null,
				// 	unit: "€",
				// 	acceso: 'VER_CONTRATOS',
				// },
				// deuda: {
				// 	icon: "mdi-currency-eur-off",
				// 	texto: "Deuda",
				// 	value: null,
				// 	unit: "€",
				// 	acceso: 'VER_CONTRATOS',
				// },
				consumos: {
					icon: "mdi-lightning-bolt",
					texto: "Consumos totales",
					value: null,
					unit: "",
					acceso: 'VER_CONTRATOS',
				},
			},
		};
	},
	methods: {
		async getTopCardsInfo() {
			let str = this.$store.getters.getDatosEmpresa;

			// const { IdUsuario, IdAgente } = str.iss;
			// const IdEmpresa = this.$store.getters.getIdEmpresa;
			// axios({
			// 	method: "GET",
			// 	url: `${process.env.VUE_APP_API_URL}/Agente/ResumenTotal/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Agente/${IdAgente}`,
			// }).then((res) => {
			// 	let temp = processToken(res.data).iss;
			// 	this.situacionesContratos.contratosActivos.value = temp.TotalContratosActivos;
			// 	this.situacionesContratos.totalClientes.value = temp.TotalClientes;
			// 	// this.situacionesContratos.comisiones.value = Number( temp.TotalComision ).toFixed(2);
			// 	// this.situacionesContratos.deuda.value = Number(temp.TotalDeuda).toFixed( 2 );
			// });

			const { IdUsuario, IdAgente } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;

			const redondear = (bytes, dp = 3) => {
				const thresh = 1000;

				if (Math.abs(bytes) < thresh) {
					return bytes + " B";
				}

				const units = ["kWh", "MWh", "GWh", "TWh", "PWh", "EWh", "ZWh", "YWh"];
				let u = -1;
				const r = 10 ** dp;

				do {
					bytes /= thresh;
					++u;
				} while (
					Math.round(Math.abs(bytes) * r) / r >= thresh &&
					u < units.length - 1
				);

				return bytes.toFixed(dp) + " " + units[u];
			};

			const filtroPeriodo = (fecha) => parseDate(fecha, true) <= ( Date.now() - (1000 * 3600 * 24 * 7) )
      const diffPorcentajes = (a, b) => parseFloat((a - b) / a * 100).toFixed(2) * 1

			const { data: contratos } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/contratosVerificados.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdAgente,
					codigoContrato: this.codigoContrato,
				},
			})

			this.situacionesContratos.totalContratos.value = contratos.length

			const conActivosAhora = contratos.filter(c => c.Situacion == 'Activado');
			// const conActivosPeriodoAnterior = contratos.filter(c => (c.Situacion == 'Activado' && filtroPeriodo(c.FechaAlta)) || (c.Situacion == 'Baja' && !filtroPeriodo(c.FechaBaja)) );
			this.situacionesContratos.contratosActivos.value = conActivosAhora.length
			// this.situacionesContratos.contratosActivos.increment = diffPorcentajes(conActivosAhora.length, conActivosPeriodoAnterior.length);

			const conBajaAhora = contratos.filter(c => c.Situacion == 'Baja');
			// const conBajaPeriodoAnterior = contratos.filter(c => c.Situacion == 'Baja' && filtroPeriodo(c.FechaBaja) );
			this.situacionesContratos.contratosBaja.value = conBajaAhora.length
			// this.situacionesContratos.contratosBaja.increment = diffPorcentajes(conBajaAhora.length, conBajaPeriodoAnterior.length);

			const conCortadoAhora = contratos.filter(c => c.Situacion == 'Cortado');
			// const conCortadoPeriodoAnterior = contratos.filter(c => c.Situacion == 'Cortado' && filtroPeriodo(c.FechaBaja) );
			this.situacionesContratos.contratosCortado.value = conCortadoAhora.length
			// this.situacionesContratos.contratosCortado.increment = diffPorcentajes(conCortadoAhora.length, conCortadoPeriodoAnterior.length);


			const totalClientesAhora = [...new Set(conActivosAhora.map(c => c.IdCliente))];
			// const totalClientesPeriodoAnterior = [...new Set(conActivosPeriodoAnterior.map(c => c.IdCliente))];
			this.situacionesContratos.totalClientesAlta.value = totalClientesAhora.length
			// this.situacionesContratos.totalClientesAlta.increment = diffPorcentajes(totalClientesAhora.length, totalClientesPeriodoAnterior.length);


			this.situacionesContratos.totalClientesAlta.value = [...new Set(conActivosAhora.map(c => c.IdCliente))].length
			this.situacionesContratos.consumos.value = redondear(conActivosAhora.reduce((a, c) => a + Number(c.ConsumoEstimado), 0) * 1000)

			// axios({
			// 	url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
			// 	method: 'GET',
			// 	params: {
			// 		token: this.$store.getters.getJwtEmpresa,
			// 		menuTab: 'actual',
			// 	}
			// }).then(res => {
			// 	this.situacionesContratos.numeroImpagados.value = res.data.length;
			// 	// console.log(res.data.reduce( (a,b) => Number(a.ImporteTotal) + Number(b.ImporteTotal), 0 ))
			// 	this.situacionesContratos.totalImpagados.value = parseFloat(res.data.reduce((a, b) => a + Number(b.ImporteTotal), 0)).toFixed(2);

			// 	const clientesBaja = [...new Set(
			// 		res.data.filter(imp =>
			// 			res.data.filter(cli => cli.IdCliente == imp.IdCliente).some(contrato => contrato.situacionContrato !== 'Activado')
			// 		).map(i => i.Identificador))];

			// 	this.situacionesContratos.totalClientesBajaDeuda.value = clientesBaja.length;
			// })

			// axios({
			// 	method: "GET",
			// 	url: `${process.env.VUE_APP_API_URL}/Contrato/GetDTOAllContratos/?Contratacion_FinderFiltersContratacionAPIDTO={"IdEmpresa":${IdEmpresa},"IdUsuario":${IdUsuario},"IdAgente":${IdAgente}}`,
			// }).then((res) => {
			// 	this.situacionesContratos.totalContratos.value = processToken(
			// 		res.data
			// 	).iss.length;
			// });

			// this.situacionesContratos.consumos.value = await consumosTotalesClientes();

		},
	},
	mounted() {
		this.getTopCardsInfo();
	},
};
</script>

<style>
.dot-elastic {
	position: relative;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--v-secondary-base);
	color: var(--v-secondary-base);
	animation: dotElastic 1s infinite linear;
	animation-delay: 125ms;
}
.dot-elastic::before,
.dot-elastic::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-elastic::before {
	left: -12px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--v-secondary-base);
	color: var(--v-secondary-base);
	animation: dotElastic 1s infinite linear;
}

.dot-elastic::after {
	left: 12px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--v-secondary-base);
	color: var(--v-secondary-base);
	animation: dotElastic 1s infinite linear;
	animation-delay: 250ms;
}
@keyframes dotElastic {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-5px);
	}
	50% {
		transform: translateY(0);
	}
	75% {
		transform: translateY(5px);
	}
	100% {
		transform: translateY(0);
	}
}
</style>